import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule } from 'angular-highcharts';
import { MaterialModule } from '../material.module';
import { PieLegendDirective } from './pie-legend.directive';
import { PieComponent } from './pie/pie.component';
import { TrendComponent } from './trend/trend.component';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, ChartModule],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    TrendComponent,
    PieLegendDirective,
    PieComponent
  ],
  declarations: [TrendComponent, PieLegendDirective, PieComponent]
})
export class SharedModule {}
