import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'dvt-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
  constructor(public location: Location) {}
}
