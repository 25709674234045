import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Errors } from 'src/app/core/errors/errors.enum';

@Component({
  selector: 'dvt-error-snack-bar',
  templateUrl: './error-snack-bar.component.html',
  styleUrls: ['./error-snack-bar.component.scss']
})
export class ErrorSnackBarComponent {
  readonly Errors = Errors;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public error: string,
    public snackBar: MatSnackBarRef<ErrorSnackBarComponent>
  ) {}
}
