import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { pluck } from 'rxjs/operators';
import { SignService } from '../sign/sign.service';

@Component({
  selector: 'dvt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  /** Current user */
  me$ = this.route.data.pipe(pluck('auth'));

  /** Mobile media matcher */
  mediumQuery: MediaQueryList;

  closeMenuWhenWhangeItem = false;

  /** Drawer */
  @ViewChild(MatSidenav, { static: true }) menu: MatSidenav;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private signs: SignService,
    private route: ActivatedRoute,
    media: MediaMatcher
  ) {
    this.mediumQuery = media.matchMedia('(max-width: 1280px)');
  }

  ngOnInit() {
    this.menu.openedChange.subscribe(() => dispatchEvent(new Event('resize')));
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe(result => {
        if (result.matches) {
          this.menu.close();
          this.closeMenuWhenWhangeItem = true;
        } else {
          this.menu.open();
          this.closeMenuWhenWhangeItem = false;
        }
      });
  }

  /**
   * Log out the user
   */
  disconnect() {
    this.signs.logout();
  }

  hideMenu() {
    if (this.closeMenuWhenWhangeItem || this.mediumQuery.matches) {
      this.menu.close();
    }
  }

  /**
   * Make a route animation
   * @param outlet router outlet
   *
   * @TODO : Make tests on spec.ts !
   */
  prepareRoute(outlet: RouterOutlet): string {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
