import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './home/not-found/not-found.component';
import { UnauthorizedComponent } from './home/unauthorized/unauthorized.component';
import { SignInComponent } from './sign/sign-in/sign-in.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { UserResolver } from './user/user.resolver';

export const routes: Routes = [
  { path: '', redirectTo: 'login/user', pathMatch: 'full' },
  { path: 'login', redirectTo: 'login/user', pathMatch: 'full' },
  { path: 'login/user', component: SignInComponent },
  {
    path: 'home',
    canActivate: [AuthGuard],
    resolve: { auth: UserResolver },
    component: HomeComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          theme: 'dashboard-theme'
        }
      },
      { path: 'statistics', redirectTo: 'statistics/billing', pathMatch: 'full' },
      {
        path: 'statistics',
        data: { theme: 'statistics-theme' },
        children: [
          {
            path: 'billing',
            loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
          },
          {
            path: 'caring',
            loadChildren: () => import('./caring/caring.module').then(m => m.CaringModule)
          }
        ]
      },
      { path: 'admin', redirectTo: 'admin/users', pathMatch: 'full' },
      {
        path: 'admin',
        canActivateChild: [AdminGuard],
        data: {
          theme: 'admin-theme'
        },
        children: [
          {
            path: 'users',
            loadChildren: () => import('./user/user.module').then(m => m.UserModule)
          }
        ]
      },
      // {
      //   path: 'service',
      //   canActivateChild: [AuthGuard],
      //   data: { theme: 'warehouse-theme' },
      //   children: [
      //     {
      //       path: '',
      //       loadChildren: () => import('./service-container/service-container.module').then(m => m.ServiceContainerModule),
      //     },
      //     {
      //       path: 'products/:service',
      //       loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
      //     },
      //     {
      //       path: 'warehouse',
      //       loadChildren: () => import('./warehouse/warehouse.module').then(m => m.WarehouseModule),
      //     }
      //   ]
      // }
    ]
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
  { path: '404', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
