import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { mergeMap, tap, filter } from 'rxjs/operators';

@Component({
  selector: 'dvt-trend',
  templateUrl: './trend.component.html',
  styleUrls: ['./trend.component.scss']
})
export class TrendComponent implements OnInit {
  trend = new Chart({
    chart: {
      type: 'column',
      spacingBottom: 0,
      height: 250,
      style: {
        fontFamily: 'Varela Round',
        fontWeight: 'bold'
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: ''
    },
    tooltip: {
      formatter() {
        const { color, series, y, description } = this.point as any;
        const { dataLabels, stack } = this.series.options as any;
        const date = new Date(description);
        return `
          <div class="tooltip-highchart mat-elevation-z5">
            <div class="tooltip-header" style="color:${color}"> ${series.name} <small>${stack ? stack : ''}</small></div>
            <div class="tooltip-value"> ${y} ${dataLabels.type} </div>
            <div class="tooltip-description">${date.toLocaleDateString()}</div>
          </div>
        `;
      },
      useHTML: true,
      className: '',
      borderWidth: 0,
      borderRadius: 0,
      backgroundColor: '#fff',
      padding: 0,
      shadow: false,
      hideDelay: 200
    },
    xAxis: {
      plotBands: [],
      tickInterval: 0,
      type: 'datetime',
    },
    yAxis: {
      allowDecimals: true,
      min: 0,
      gridLineDashStyle: 'Dash',
      title: {
        text: ''
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      areaspline: {
        opacity: 0
      },
      series: {
        animation: false, // <--- Disable animation
        cursor: 'pointer',
        point: {
          events: {
            // mouseOver: (event) => { }
          }
        }
      }
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 910 // 910px === medium breakpoint in flex Layout
          },
          chartOptions: {
            yAxis: {
              visible: false,
              title: {
                text: ''
              }
            },
            plotOptions: {
              column: {
                pointPadding: 0,
                maxPointWidth: 10,
                borderWidth: 1
              }
            }
          }
        }
      ]
    }
  });

  @Input()
  color = 'rgba(68, 170, 213, .2)';

  @Input()
  borderColor = '#3c4148';

  @Input()
  get series(): Highcharts.SeriesAreasplineOptions[] {
    return this.series$.value;
  }
  set series(value: Highcharts.SeriesAreasplineOptions[]) {
    this.cd.detectChanges();
    this.series$.next(value);
  }
  private series$ = new BehaviorSubject<Highcharts.SeriesAreasplineOptions[]>([]);

  constructor(private cd: ChangeDetectorRef, breakpointObserver: BreakpointObserver) {
    combineLatest([
      breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]),
      this.trend.ref$.pipe(filter(ref => !!ref.series))
    ]).subscribe(([{matches}, {series}]) => {
      series
        .filter(serie => serie.options.className === 'current')
        .map(serie => serie.update({
          type: 'column',
          pointWidth: matches ? 10 : 30,
        }));
      series
        .filter(serie => serie.options.className === 'previous')
        .map(serie => serie.update({
          type: 'column',
          borderWidth: matches ? 0 : 2,
          pointWidth: matches ? 5 : 10,
        }));
    });
  }

  ngOnInit() {
    this.series$.pipe(
      mergeMap((series: Highcharts.SeriesAreasplineOptions[]) => {
        return this.trend.ref$.pipe(tap(ref => {
          this.series
            .map(serie => ref.get(serie.id))
            .filter(serie => !!serie)
            .map(serie => serie.remove(false));
          series.map(serie => {
            if (serie.className === 'previous') {
              this.trend.addSeries({
                ...serie,
                type: 'column',
                pointPadding: 0,
                pointWidth: 10,
                zIndex: 9,
                borderWidth: 2
              }, false, false);
            } else {
              this.trend.addSeries({
                ...serie, type: 'column', pointPadding: 0, pointWidth: 30 }, false, false);
            }
          });
        }));
      }),
    ).subscribe(() => this.trend.ref.redraw());
  }

}
