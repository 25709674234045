import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenService } from '../core/token/token.service';
import { ApiToken } from './api-token';

@Injectable({
  providedIn: 'root'
})
export class SignService {
  constructor(private http: HttpClient, private tokens: TokenService, private router: Router) {}

  /**
   * Log the user
   * @param login User's login
   * @param password User's password
   * @param rememberMe User's choice rememberMe
   *
   * @returns Observable containing token
   */
  signIn(login: string, password: string, rememberMe: boolean): Observable<ApiToken> {
    return this.http
      .post<ApiToken>(`${environment.api}/auth/login`, {
        login,
        password: btoa(password)
      })
      .pipe(
        tap(token => {
          this.tokens.set(token.access_token);
          if (rememberMe) {
            this.tokens.persist(token.access_token);
          }
        })
      );
  }

  /**
   * Disconnect the user
   */
  logout(): Promise<boolean> {
    this.tokens.clear();
    return this.router.navigate(['/login/user']);
  }
}
