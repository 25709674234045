import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { BehaviorSubject } from 'rxjs';
import { PieLegendDirective } from '../pie-legend.directive';

@Component({
  selector: 'dvt-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {
  /** Reference for a template */
  @ContentChild(PieLegendDirective, { static: false, read: TemplateRef })
  legendTemplateRef: TemplateRef<any>;

  /** Pie graphic */
  pie: Chart;

  /** Array of colors */
  @Input()
  colors: string[];

  /** SVG icon in the center of donuts pie */
  @Input()
  icon: string;

  /** Pie width */
  @Input()
  width = 250;

  /** Pie height */
  @Input()
  height = 250;

  /** The position of legend compared to graphic pie */
  @Input()
  reversedRow: boolean;

  /** Series */
  @Input()
  get series(): any[] {
    return this.series$.value;
  }
  set series(value) {
    this.series$.next(value);
  }

  /** Series BehaviorSubject */
  series$ = new BehaviorSubject<any[]>(null);

  ngOnInit() {
    this.pie = new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        spacing: [0, 0, 0, 0],
        width: this.width,
        height: this.height,
        margin: [0, 0, 0, 0],
        style: {
          fontFamily: 'Varela Round'
        }
      },
      credits: {
        enabled: false
      },
      title: {
        useHTML: true,
        text: this.icon,
        align: 'center',
        verticalAlign: 'middle'
      },
      tooltip: {
        headerFormat: '',
        backgroundColor: '#fff',
        style: {
          zIndex: 99
        },
        pointFormat: `
          <span style="
            padding: 5px;
            color:{point.color};
            font-weight:bold;
            font-size: 17;
            display: block;
            text-transform: uppercase;
            letter-spacing: .1em;
            font-family:Poppins">
            {point.name}
          </span><br />
          <span style="
            font-weight:bold;
            display: block;
            font-size: 14;
            font-family:Poppins">{point.percentage:.1f}% - {point.y}
          </span>
          `,
        borderRadius: 15,
        padding: 10
      },
      plotOptions: {
        pie: {
          size: '100%',
          allowPointSelect: true,
          borderColor: 'transparent',
          cursor: 'pointer',
          colors: this.colors,
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          showInLegend: false
        }
      },
      series: [
        {
          name: 'transactions',
          type: 'pie',
          innerSize: '50%',
          data: []
        }
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 700
            },
            chartOptions: {
              // chart: {
              //   height: 200,
              //   className: 'small'
              // },
            }
          }
        ]
      }
    });

    this.series$.subscribe(series => {
      this.pie.removeSeries(0);
      this.pie.addSeries(
        {
          type: 'pie',
          innerSize: '50%',
          data: series
        },
        true,
        true
      );
    });
  }
}
