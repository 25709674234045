import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ErrorSnackBarComponent } from './error-snack-bar/error-snack-bar.component';
import { HomeComponent } from './home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
  declarations: [HomeComponent, NotFoundComponent, UnauthorizedComponent, ErrorSnackBarComponent],
  imports: [RouterModule, SharedModule],
  entryComponents: [ErrorSnackBarComponent]
})
export class HomeModule {}
