import { Component, HostBinding } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router, RoutesRecognized } from '@angular/router';
import { delay, filter, map, pluck } from 'rxjs/operators';

@Component({
  selector: 'dvt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostBinding('class')
  theme = 'default-theme';

  constructor(router: Router, sanitizer: DomSanitizer, iconRegistry: MatIconRegistry) {
    iconRegistry
      .addSvgIcon('login', sanitizer.bypassSecurityTrustResourceUrl('assets/login.svg'))
      .addSvgIcon('password', sanitizer.bypassSecurityTrustResourceUrl('assets/password.svg'))
      .addSvgIcon('logo', sanitizer.bypassSecurityTrustResourceUrl('assets/logo.svg'))
      .addSvgIcon(
        'dashboard',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard1.svg')
      )
      .addSvgIcon('admin', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/admin1.svg'))
      .addSvgIcon('user', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/user.svg'))
      .addSvgIcon('user-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-add.svg'))
      .addSvgIcon('users', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-multi.svg'))
      .addSvgIcon('firms', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/firms.svg'))
      .addSvgIcon('services', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/firms.svg'))
      .addSvgIcon('country', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/countries.svg'))
      .addSvgIcon('euro', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/euro.svg'))
      .addSvgIcon('ticket', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ticket.svg'))
      .addSvgIcon('increase', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/increase.svg'))
      .addSvgIcon('reload', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/reload.svg'))
      .addSvgIcon('dicrease', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dicrease.svg'))
      .addSvgIcon('pen', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pen.svg'))
      .addSvgIcon('owner', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/owner.svg'))
      .addSvgIcon('stock', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/stock.svg'))
      .addSvgIcon('trash', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash.svg'))
      .addSvgIcon(
        'global',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scope-global.svg')
      )
      .addSvgIcon('firm', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/companies.svg'))
      .addSvgIcon('filter', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter.svg'))
      .addSvgIcon(
        'statistics',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/stats-2.svg')
      )
      .addSvgIcon('sad', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sad.svg'))
      .addSvgIcon(
        'operator',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/operators.svg')
      )
      .addSvgIcon(
        'services',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/customer-support.svg')
      )
      .addSvgIcon('empty', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/empty.svg'));

    router.events
      .pipe(
        filter(event => event instanceof RoutesRecognized),
        pluck<RoutesRecognized, ActivatedRouteSnapshot>('state', 'root'),
        map(root => {
          if (!root.firstChild || !root.firstChild.firstChild || !root.firstChild.firstChild.firstChild) {
            this.theme = 'default-theme';
          }
          return root;
        }),
        filter(root => !!root.firstChild && !!root.firstChild.firstChild && !!root.firstChild.firstChild.firstChild),
        pluck('firstChild', 'firstChild', 'firstChild', 'data', 'theme'),
        delay(700)
      )
      .subscribe(theme => (this.theme = theme ? theme : 'default-theme'));
  }
}
