import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  /** The stored JWT token. Default to null */
  private token: string = null;

  /** The localStorage key where the token will be persisted */
  private storageKey = 'token';

  /**
   * Retrieve stored token in localStorage or in the service itself if not persisted
   * @returns The JWT stored token
   */
  get(): string {
    return localStorage.getItem(this.storageKey) || this.token;
  }

  /**
   * Store a JWT token into the service itself
   * @param token The JWT token to store
   */
  set(token: string) {
    this.token = token;
  }

  /**
   * Store a JWT token into localStorage
   * @param value The JWT token to store
   */
  persist(value: string): void {
    localStorage.setItem(this.storageKey, value);
  }

  /**
   * Remove token from both localStorage and the service
   */
  clear(): void {
    this.token = null;
    localStorage.removeItem('token');
    localStorage.removeItem(this.storageKey);
  }
}
