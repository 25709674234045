import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/user/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private http: HttpClient) {}

  /**
   * Make Admin page unavailable if user isn't admin
   */
  canActivate(): Observable<boolean | UrlTree> {
    return this.http.get<User>(`${environment.api}/auth/me`).pipe(
      catchError(() => of({ role: [] })),
      map(user => user.role.includes('admin') || this.router.parseUrl('/unauthorized'))
    );
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}
