export enum Errors {
  // L'utilisateur n'a pas les droits
  notAuthorized = 'User is not authorized to access this resource with an explicit deny',
  // Erreur côté Front, donc on affiche un message général
  attributesNotStructured = 'Cannot destructure property `attributes` of \'undefined\' or \'null\'.',
  // Pas connecté
  notConnected = 'Error : You are not connected !',
  // Time out
  timeOut = 'Endpoint request timed out',
  // Result without id
  wrongObjectProperty = 'Cannot read property \'id\' of undefined',
  // Wrong login or password
  wrongId = 'Error : fail to connect user vodafoneit <wrong login or pass>',
  // User with the same login already exist
  loginAlreadyTaken = 'Error : Error while creating the account : user with the same login already exist',
  // User with the same login already exist
  userAlreadyExist = 'User found',
}
