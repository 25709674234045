import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { ErrorSnackBarComponent } from 'src/app/home/error-snack-bar/error-snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(public snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('token')) {
      return next.handle(req).pipe(
        catchError(res => {
          this.snackBar.openFromComponent(ErrorSnackBarComponent, {
            data: res.error.errors.message || res.error.errors.title,
            panelClass: 'snack-bar-error',
            duration: 5000
          });
          return throwError(res);
        })
      );
    }

    return next.handle(req);
  }
}
