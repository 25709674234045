import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SignService } from '../sign.service';

@Component({
  selector: 'dvt-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
  @ViewChild('error', { static: true }) errorTemplate: TemplateRef<any>;

  form: FormGroup;
  isLoading = false;
  errorMessage: string;
  showPassword = false;

  constructor(private signs: SignService, private router: Router, fb: FormBuilder) {
    this.form = fb.group({
      login: [''],
      password: ['', [Validators.required]],
      rememberMe: ['']
    });
  }

  /**
   * Log the user
   */
  onSubmit() {
    this.isLoading = true;
    const { login, password, rememberMe } = this.form.value;

    this.signs
      .signIn(login, password, rememberMe)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          const currentUrl = localStorage.getItem('retrieveUrl');
          this.router.navigateByUrl(currentUrl || '/home');
          localStorage.removeItem('retrieveUrl');
        },
        err => (this.errorMessage = err.message)
      );
  }
}
